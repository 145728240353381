<template>
  <div class="tw-my-9">
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form id="createProfile" @submit.prevent="handleSubmit(submit)">

        <div class="my-4">
          <div class="mb-1 tw-flex">
            <small>Profile Picture</small>
          </div>
          <ProfilePicture :imageUploading="imageUploading" @change="handleImage"/>
          <div class="tw-my-6">
            <small>Tell us about yourself</small>
            <ValidationProvider
                v-slot="{ errors }"
                name="company name"
                rules="required">
              <v-textarea v-model="message" hide-details solo></v-textarea>
              <span v-show="errors" class="err">{{ errors[0] }} </span><br>
            </ValidationProvider>
            <small>You can always change this later</small>
          </div>
          <div class="tw-flex">
            <BaseButton button-text="Skip" class="tw-mr-3" outlined @click="skipTo"/>
            <BaseButton button-text="Continue" width="148">
              <template #right-icon>
                <ArrowRightIcon class="arrow" size="20"/>
              </template>
            </BaseButton>
          </div>
        </div>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>

import BaseButton from "@/components/UIComponents/BaseButton";
import {ArrowRightIcon} from "vue-feather-icons"
import ProfilePicture from "@/components/reusables/ProfilePicture"
import {nonExistingAdminAccount, uploadToCloudinary} from "@/services/api/APIService";

export default {
  name: "StepTwo",
  components: {BaseButton, ArrowRightIcon, ProfilePicture},
  data() {
    return {
      showDragDiv: true,
      loading: false,
      message: '',
      logo: '',
      color: '',
      imageUploading: false,
      imageInfo: {}
    }
  },

  methods: {
    async submit() {
      this.loading = true
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      let updateInfo = JSON.parse(sessionStorage.getItem('adminDetails'))
      let userToken = {...userData}
      userToken.site_name = updateInfo.siteName
      sessionStorage.setItem("userData", JSON.stringify(userToken));
      console.log(updateInfo, '4m step1')
      if(this.file){
        await this.uploadToCloudinary(this.file)
        updateInfo.image = this.imageInfo.url
        updateInfo.profile_message = this.message
      }

      console.log(updateInfo, 'plus step2')
      nonExistingAdminAccount(updateInfo).then(res => {
        console.log(res)
        this.$displaySnackbar({
          message: "Account Updated Successfully",
          success: true
        })
        this.$router.push({name: 'RecruiterDashboard'})
      })
    },
    async skipTo() {
      this.loading = true
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      let updateInfo = JSON.parse(sessionStorage.getItem('adminDetails'))
      let userToken = {...userData}
      userToken.site_name = updateInfo.siteName
      sessionStorage.setItem("userData", JSON.stringify(userToken));
      console.log(updateInfo, '4m step1')
      console.log(updateInfo, 'only step1?')
      nonExistingAdminAccount(updateInfo).then(res => {
        console.log(res)
        this.$displaySnackbar({
          message: "Account Updated Successfully",
          success: true
        })
        this.$router.push({name: 'RecruiterDashboard'})
      })
    },

    async handleImage(file) {
      this.loading = true
      this.file = file;
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension)) {
        this.file = "";
        return
      }
    },

    async uploadToCloudinary(file) {
      this.imageUploading = true
      const formData = new FormData();
      formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      );
      console.log(formData);
      formData.append("folder", "company_logo");
      formData.append("file", file);
      const {
        data: {
          secure_url: url,
          original_filename: name,
          bytes: size
        }
      } = await uploadToCloudinary(formData);
      this.imageInfo = {url: url, name: name, size: size}
      this.imageUploading = false
    },

    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 40)
    },

    fileSize(size) {
      if (!size) return ''
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },

    onChange() {
      this.file = this.$refs.file.files[0];
      this.showDragDiv = false;
    },

    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },

    dragleave() {
      this.color = "#FBFDFE";
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.color = "#FBFDFE";
    },

    remove() {
      this.file = "";
      this.showDragDiv = true;
    },
  }
}
</script>

<style scoped>
.drag-div {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  width: 280px;
  min-height: 60px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}

.arrow {
  color: white;
  margin-left: 3px;
}

.file {
  margin-bottom: 15px;
  margin-top: 15px;
}

.wrapper {
  padding-top: 75px;
  margin: auto;
}

.edit {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.drag-div {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  width: 280px;
  height: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans", serif;
}

.size__instruction {
  font-size: 11px;
}

p {
  font-family: "DM Sans", serif;
}

.infor {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
  margin-top: 20px;
}

.width {
  margin-bottom: 25px;
}

.logo {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #001343;
}
</style>
