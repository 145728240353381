<template>
  <div class="md:tw-flex tw-w-full">
    <label for="upload-input">
      <v-progress-linear
          color="#53a6ef"
          indeterminate
          rounded
          height="6"
          class="tw-flex tw--mb-5"
          v-if="imageUploading === true"
      ></v-progress-linear>
      <span
          id="drag-area"
          v-ripple="false"
          :class="dragClasses"
          class="d-flex mt-5 align-center pa-3"
          style="width: 100%"
          @dragleave="onDragLeave"
          @dragover.prevent="onDraggedOver"
          @drop.prevent="uploadImage"
      >
      <input
          id="upload-input"
          ref="upload_input"
          accept="img/*"
          style="display: none"
          type="file"
          @change="uploadImage"
      />
      <span v-if="!isDraggedOver && !imageInfo.image" class="d-flex flex-column justify-center align-center">
        <v-icon color="black" size="36">mdi-cloud-upload-outline</v-icon>
        <span class="font-weight-bold instruction tw-text-xs md:tw-text-base">Drag and drop or <span class="choose__file">choose file</span></span>
        <small class="tw-text-xs md:tw-text-base">240x346 px Recommended, max size 1MB</small>
      </span>
      <span v-else class="d-flex" style="width: 100%">
        <span class="image__preview mr-4" :class="{ 'full-height': imageInfo.image }">
          <v-img
              v-if="imageInfo.image"
              :src="imageInfo.image"
              class="round-image"
              height="100%"
              transition="fade"
          />
        </span>
        <span class="image__info tw-mt-14 tw-flex tw-flex-col">
          <span class="image__actions d-flex align-end tw-ml-12" :class="{ 'bg-transparent': imageInfo.image }">
            <span v-if="imageInfo.image">
              <v-btn
                  class="mr-3 replace__btn tw-mb-3 lg:tw-mb-2"
                  depressed
                  @click.prevent="openExplorer"
              >
                Replace
              </v-btn>
              <v-btn class="delete__btn" depressed @click.prevent="clearImage">Delete</v-btn>
            </span>
          </span>
        </span>
      </span>
    </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ProfilePicture",
  data: () => ({
    isDraggedOver: false,
    imageInfo: {
      image: "",
      name: "",
      size: "",
    },
    imageInfoBeforeDrag: {
      image: "",
      name: "",
      size: "",
    },
  }),
  computed: {
    dragClasses() {
      const { isDraggedOver, imageInfo, increaseHeight } = this;
      return {
        dragged__over: isDraggedOver || imageInfo.image,
        "justify-center": !isDraggedOver && !imageInfo.image,
        "increase-height": increaseHeight,
      };
    },
  },
  props: {
    imageObject: Object,
    increaseHeight: Boolean,
    imageUploading : Boolean
  },
  watch: {
    imageObject: {
      handler: function (imageObject) {
        if (!imageObject) return;
        const { image, name, size } = imageObject;
        this.imageInfo.image = image;
        this.imageInfo.name = name;
        this.imageInfo.size = size;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    uploadImage(event) {
      let file;
      if (event.type === "drop") {
        file = event.dataTransfer.files[0];
      } else {
        this.isDraggedOver = true;
        file = event.target.files[0];
      }
      const reader = new FileReader();
      const vueComp = this;
      console.log(file);
      reader.onload = function (ev) {
        vueComp.imageInfo.image = ev.target.result;
        vueComp.imageInfo.name = file.name;
        vueComp.imageInfo.size = file.size;
        vueComp.$emit("change", file);
        vueComp.$emit("image-object", vueComp.imageInfo);
      };
      reader.readAsDataURL(file);
    },
    openExplorer() {
      this.$refs.upload_input.click();
    },
    onDraggedOver() {
      this.isDraggedOver = true;
      if (this.imageInfo.image) {
        // eslint-disable-next-line no-undef
        this.imageInfoBeforeDrag = _.cloneDeep(this.imageInfo);
      }
      Object.keys(this.imageInfo).forEach(
          (info) => (this.imageInfo[info] = "")
      );
    },
    onDragLeave() {
      this.isDraggedOver = false;
      Object.keys(this.imageInfoBeforeDrag).forEach(
          (info) => (this.imageInfo[info] = this.imageInfoBeforeDrag[info])
      );
    },
    shortenFileName(filename) {
      if (!filename) return "";
      if (filename) return filename;
      let name = filename.split(".")[0];
      const extension = filename.split(".")[1];
      if (name.length > 15) {
        name = name.substr(0, 10) + "..." + name.substr(-5);
      }
      console.log(name,extension)
      return name + "." + extension;
    },
    getFileSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (size / Math.pow(1024, i)).toFixed(2) * 1 +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    clearImage() {
      Object.keys(this.imageInfo).forEach(
          (info) => (this.imageInfo[info] = "")
      );
      Object.keys(this.imageInfo).forEach(
          (info) => (this.imageInfoBeforeDrag[info] = "")
      );
      this.isDraggedOver = false;
      this.$emit("image-clear");
    },
    loadImage(){
      let image = this.profileData
      if (image){
        this.imageInfo.image = image.image
        // eslint-disable-next-line no-undef
        this.imageInfo.name = _(image.image_name).truncate(1)
        this.imageInfo.size = image.image_size
      }
    }
  },
  async created() {
    await this.loadImage()
  }
};

</script>

<style lang="scss" scoped>
#drag-area {
  background: #F7FCFF !important;
  box-sizing: border-box;
  min-height: 150px;
  cursor: pointer;
  border-radius: 8px !important;
  border: 1px dashed #008EEF;
}

.dragged__over {
  border-radius: 8px;
  border: none !important;
  background: transparent !important;
}

.choose__file {
  color: #53a6ef !important;
}

.image__preview {
  height: 100px;
  width: 90px;
  border-radius: 8px;
  background: #eaeaea;
}

.image__info {
  width: calc(100% - 90px);
  height: auto;
}

.image__size,
.image__title,
.image__preview,
.image__actions {
  background: #eaeaea;
}

.round-image {
  border-radius: 5px !important;
}

.image__title {
  width: 100%;
  height: 20px;
}

.image__size {
  width: 35%;
  height: 20px;
  border-radius: 5px;
}

.image__actions {
  width: 40%;
  height: 45px;
}

.bg-transparent {
  background: transparent !important;
}

.replace__btn {
  background: #2b1c1c !important;
  color: #fff;
}

.delete__btn {
  background: #f5f7f8 !important;
}

.increase-height {
  min-height: 280px !important;
  margin-top: 0px !important;
}
</style>
