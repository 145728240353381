<template>
  <div>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(nextPage())">
        <div class="my-9">
          <div class=" d-flex">
            <small>Job Role</small>
            <p class="asterisk ml-1">*</p>
          </div>
          <ValidationProvider
              v-slot="{ errors }"
              :rules="{
                  required: true,
                  regex:  /^([A-Z,a-z\s]+)$/
                 }"
              name="job role">
            <v-combobox id="jobRole"
                        hide-details
                        label="Enter your role"
                        v-model="role"
                        :items="roles"
                        solo/>
            <span v-show="errors" class="asterisk">
              {{ errors[0] === "The job role field format is invalid" ? ", it accepts letters only" : "" }}
            </span>
          </ValidationProvider>
        </div>
        <small class="label">Gender</small>
        <div class="tw-flex tw-mt-3 tw-mb-6">
          <div  v-for="status in gender" :key="status" @click="selectGender(status)"
                :class="[status === selected ? 'selected' : 'select']"
                class="tw-mr-5 tw-mb-5" >
            <span >{{status}}</span>
          </div>
        </div>
        <BaseButton button-text="Continue" width="148" type="submit">
          <template #right-icon>
            <ArrowRightIcon class="arrow" size="20"/>
          </template>
        </BaseButton>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>

import BaseButton from "@/components/UIComponents/BaseButton"
import {ArrowRightIcon} from "vue-feather-icons"

export default {
  name: "StepOne",
  components: {BaseButton, ArrowRightIcon},
  data(){
    return {
      roles: ['CEO','CTO','Talent Manager','Secretary', 'General Manager', 'Accountant'],
      role:'',
      gender: ['Male', 'Female'],
      adminDetails: '',
      selected: ''
    }
  },
  methods: {
    selectGender(item){
      return this.selected = item
    },

    nextPage() {
      let adminInfo = JSON.parse(sessionStorage.getItem('adminDetails'))
      console.log(adminInfo, 'site-name')
      adminInfo.job_role = this.role
      adminInfo.gender = this.selected
      sessionStorage.setItem('adminDetails', JSON.stringify(adminInfo))
      this.$router.push({name: 'InviteAnAdminView', params: {step: '2'}})
    }
  }
}
</script>

<style scoped>
.gender {
  height: 56px;
  background: #FFFFFF;
  border: 1px solid #D0DCE4;  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 16px 32px;
}

.asterisk {
  font-family: "DM Sans", serif;
  color: red;
  font-size: 13px;
}

.arrow {
  color: white;
  margin-left: 5px;
}

.select-input{
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans,serif;
  color: #001343;
  cursor: pointer;
}
.selected {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  cursor: pointer;
}
.select {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  border: 2px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>
