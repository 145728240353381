<template>
  <Onboardlayout>
    <template v-slot:header>
      <Header/>
    </template>

    <template  v-slot:main-content>
      <div class="tw-h-full mb-5 main__content">
        <div v-show="$route.params.step === '1'&& !first_login "  class="left tw-m-auto pt-5 lg:tw-w-10/12">
          <button @click="$router.back()" class="tw-flex">
            <ChevronLeftIcon/>
            <p class="tw-font-bold tw-text-base" >Back</p>
          </button>
        </div>
        <div v-show="$route.params.step !== '1'"  class="left tw-m-auto pt-5 lg:tw-w-10/12">
          <button @click="goBack()" class="tw-flex">
            <ChevronLeftIcon/>
            <p class="tw-font-bold tw-text-base" >Back</p>
          </button>
        </div>

        <div class="applyFlexCenter profile-content">
          <div>
            <v-card flat class="tw-w-80 tw-mt-10 tw-px-5 md:tw-w-96">
              <p class="tw-text-xs mb-8 tw-font-medium tw-text-enumDarkBlue">STEP {{ stepNumber }} OF 2 </p>
              <h2 class="tw-text-2xl tw-leading-7 mb-7 tw-text-enumDarkBlue tw-font-semibold">{{stepTitle}}</h2>
              <p class="tw-text-xs md:tw-text-sm tw-leading-6 tw-text-enumGrey2">{{stepDescription}}</p>

              <component  v-bind:is="returnStepContent()"></component>

            </v-card>
          </div>

        </div>
      </div>
    </template>
  </Onboardlayout>
</template>

<script>

import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import Header from "@/components/onboarding/reuseables/Header";
import BaseButton from "@/components/UIComponents/BaseButton"
import {ChevronLeftIcon} from "vue-feather-icons"
import StepOne from "@/components/dashboard/components/AdminInvite/StepOne"
import StepTwo from "@/components/dashboard/components/AdminInvite/StepTwo"

export default {
  name: "InviteAnAdminView",
  components: {Onboardlayout, Header, BaseButton, StepOne, StepTwo, ChevronLeftIcon},
  data(){
    return{
      step: this.$route.params.step,
      backRoute: ""
    }
  },
  computed:{
    first_login(){
      let firstLogin = JSON.parse(sessionStorage.getItem('userData')).first_login
      // console.log(firstLogin)
      return firstLogin
    },
    stepNumber() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "1",
        "step 2": "2",
      }
      return titles['step ' + step]
    },
    stepTitle() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "Let's get to know you",
        "step 2": "Put a face to the name",
      }
      return titles['step ' + step]
    },
    stepDescription() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "We will use this data to personalize your experience, (dont worry, we’ll keep it private)",
        "step 2": "Upload your logo and you can choose to enter your company website",
      }
      return titles['step ' + step]
    }
  },
  methods:{
    returnStepContent() {
      let step = this.$route.params.step
      if (step === "1") {
        return "StepOne";
      } else if (step === "2") {
        return "StepTwo";
      }

    },
    goBack(){
      let newStep = "";
      let step = this.$route.params.step
      if (step === "2") {
        newStep = "1"
      } else {
        newStep = "2"
      }
      this.$router.push({
        name: "InviteAnAdminView",
        params: {step: newStep}
      });
    },
    goBackOnPageOne(){
      console.log(this.backRoute)
      this.$router.push({
        name: this.backRoute
      });
    }
  },
  beforeRouteEnter(to, from, next){
    let fromRoute = from.name
      next(vm => {
      vm.$data.backRoute = fromRoute
    })

  }
}
</script>

<style scoped lang="scss">

.main__content {
  min-height: calc(100vh);
}
.profile-content {
  height: calc(100% - 64px);
}
p{
  font-family: IBM Plex Serif, serif;
}
h2{
  font-family: IBM Plex Serif, serif;
}
.left {
  margin-left: 170px;
  @media (max-width:500px) {
  margin-left: 40px;
  }
}
</style>
